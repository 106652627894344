@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

body {
  text-align: center;
  font-family: "Righteous", cursive;
}
button {
  font-weight: 600;
  border: solid 2px black;
  outline: 0;
  padding: 1rem 4rem;
  font-size: 1.5rem;
  letter-spacing: .08rem;
  background-color: white;
  border-radius: 0.35rem;
  position: relative;
  cursor: pointer;
}

button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: pink;
  z-index: -1;
  border-radius: 0.35rem;
  border: solid 2px black;
  transition: all 0.3s ease-in-out;
  border: 0;
  box-shadow: 0px 0px 0px 2px black;
  top: 0.5rem;
  left: 0.5rem;
}

button:hover::after {
  top: -0.5rem;
  left: -0.5rem;
  width: calc(100% + 0.5rem * 2);
  height: calc(100% + 0.5rem * 2);
}
